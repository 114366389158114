<template>
  <section id="banner" class="page">
    <banner-image-overlay :fullscreen="true"></banner-image-overlay>
    <div class="main-container">
      <h2 class="title">Account Confirmation</h2>
      <div class="d-flex justify-content-center">
        <article>
          <div class="alert alert-danger align-left" v-show="error">{{ error }}</div>
          <div class="alert alert-success align-left" v-show="success">{{ success }}</div>
          <router-link :to="{ name: 'Login' }"
                       class="noselect button special"
                       v-show="success">Login</router-link>
        </article>
      </div>
    </div>
  </section>
</template>

<script>
import UserApi from '../../api/User';
import BannerImageOverlay from '../../components/common/utils/BannerImageOverlay.vue';

export default {
  name: 'AccountConfirmation',
  components: {
    BannerImageOverlay,
  },
  data() {
    return {
      error: '',
      success: '',
    };
  },
  created() {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    if (!token) {
      this.error = 'Missing account confirmation token. Please make sure that the website link is correct';
      return;
    }
    UserApi
      .accountConfirmation(token)
      .then((response) => {
        this.success = response.data.message;
      })
      .catch((error) => {
        this.error = this.parseError(error, 'Unable to confirm again. Please try again');
      });
  },
};
</script>

<style scoped>
  .button.special {
    float: left;
  }

  strong, b {
    color: #B6B6B6;
  }

  article {
    width: 500px;
  }

  a {
    text-decoration: none;
  }

  a:hover {
    color: #38AAF0;
  }

  button[type=submit] {
    float: left;
  }

  #banner {
    height: 100%;
  }

  @media screen and (max-width: 480px) {
    input[type="submit"],
    input[type="reset"],
    input[type="button"],
    button,
    .button {
        padding: 0 2.2em;
    }
  }
</style>
